<template>
  <div>
    <div class="row aliases-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.ALIAS_DOMAINS')">
          <template v-slot:toolbar>
            <b-button
              variant="primary"
              class="mr-4"
              @click="openCreateModal()"
              v-if="canAdd"
            >
              {{ $t("COMMON.NEW") }}
            </b-button>
            <b-button
              variant="danger"
              v-b-modal.confirm-1
              v-if="canRemove"
              :disabled="aliasTable.selected.length == 0"
            >
              {{ $t("COMMON.DELETE") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                :label="$t('COMMON.SEL_DOMAIN')"
                label-for="select-domain"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    @input="onChangeDomain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')">
                  </model-list-select>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                  @input="searchDomain"
                ></v-text-field>
              </div>
            </div>
            <v-data-table
              v-model="aliasTable.selected"
              :headers="headers"
              :items="aliasTable.aliases"
              :single-select="aliasTable.singleSelect"
              show-select
              item-key="id"
              class="elevation-1"
              ref="aliasTable"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:item.aliasdomaintype="{ item }">
                <span>{{ aliasDomainType[item.aliasdomaintype] }}</span>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon
                  medium
                  color="dark"
                  @click="saveDeltem(item)"
                  v-b-modal.confirm-2
                  v-b-tooltip.hover
                  v-if="canRemove"
                  :title="$t('COMMON.DELETE')"
                >
                  delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- ----------add alias modal------------- -->
            <b-modal
              id="addAlias"
              ref="addAlias"
              :title="$t('DOMAINS.ADD_ALIAS_DOMAIN')"
            >
              <b-container fluid>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label>{{ $t("COMMON.DOMAIN") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.domain.selected"
                      value-field="domain"
                      text-field="domain"
                      :options="addForm.domain.data"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="5">
                    <label for="aliasName">{{ $t("ALIASES.ALIAS") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="aliasName"
                      v-model="$v.addForm.aliasName.$model"
                      :state="validateState('aliasName')"
                      :placeholder="$t('MENU.ALIAS_DOMAINS')"
                      aria-describedby="input-aliasName-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-aliasName-feedback">
                      {{ $t("VALIDATION.REQUIRED_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose()">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="createItem()">
                  {{ $t("COMMON.ADD") }}
                </b-button>
              </template>
            </b-modal>

            <!-- ----------delete confirm modal------------- -->
            <!-- ----------- delete one alias confirm modal -->
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{
                  $t("COMMON.DEL_ITEM_WARNING", { item: delItem.alias_domain })
                }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------- delete aliases confirm modal -->
            <b-modal
              id="confirm-1"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteItems">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal end------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "aliasdomain",
  components: {
    KTPortlet,
  },
  data: () => ({
    search: "",
    aliasTable: {
      singleSelect: false,
      aliases: [],
      selected: [],
    },
    selectDomain: {
      allDomains: [],
      selectedDomain: {},
    },
    addForm: {
      domain: {
        selected: "",
        data: [],
      },
      aliasName: "",
    },
    delItem: "",
    updateForm: {
      id: "",
      alias: "",
      domain: "",
      editTitle: "",
    },
  }),
  computed: {
    headers: function () {
      return [
        { text: this.$t("COMMON.DOMAIN"), value: "parentdomain" },
        { text: this.$t("MENU.ALIAS_DOMAINS"), value: "alias_domain" },
	      { text: this.$t("COMMON.TYPE"), value: "aliasdomaintype" },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    aliasDomainType: function () {
      return {
        "1" : this.$t("COMMON.ACTIVE"),
        "9" : this.$t("DOMAINS.VERIFYING"),
        // this.$t("LISTS.SPAM_RETRAINER"),
        // this.$t("LISTS.NOT_SPAM_RETRAINER"),
        // this.$t("LISTS.EXCHNAGE_ACCOUNT")
      };
    },
    canAdd: function () {
      return this.hasPermission("domains", 5);
    },
    canRemove: function () {
      return this.hasPermission("domains", 7);
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
        this.addForm.domain.data = res.returndata;
        this.addForm.domain.selected = res.returndata[0].domain;
        this.getAliasDomainsReqest(this.selectDomain.selectedDomain.domain);
      }
    });
  },
  validations: {
    addForm: {
      aliasName: {
        required,
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.ALIAS_DOMAINS") },
    ]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      return $dirty ? !$error : null;
    },
    addformReset() {
      this.addForm.aliasName = "";
      this.addForm.domain.selected = this.addForm.domain.data[0].domain;
    },
    /*  -----modal close------- */
    modalClose() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.addformReset();
      this.$refs["addAlias"].hide();
    },
    confirmModalClose(modalItem) {
      this.$refs[modalItem].hide();
    },

    /* ---------read item---------- */
    getAliasDomainsReqest(domain) {
      this.aliasTable.aliases = [];
      this.aliasTable.selected = [];
      postRequest({
        action: "getAliasDomains",
        token: localStorage.id_token,
        domain: domain,
      }).then((res) => {
        if (res.returncode) {
          this.aliasTable.aliases = res.returndata;
        } else {
          this.aliasTable.aliases = [];
        }
      });
    },
    getAliasesForSelectedDomain(selectedDomain) {
      this.getAliasDomainsReqest(selectedDomain);
    },

    /* ------create item---- */
    openCreateModal() {
      this.addForm.domain.selected = this.selectDomain.selectedDomain.domain;
      this.$refs["addAlias"].show();
    },
    createItem() {
      this.$v.addForm.$touch();
      if (this.$v.addForm.aliasName.$anyError) {
        return;
      }
      const selectedDomainForAdd = this.addForm.domain.selected;
      const selectedDomainForPage = this.selectDomain.selectedDomain.domain;
      postRequest({
        action: "addAliasDomain",
        token: localStorage.id_token,
        adomain: this.addForm.aliasName,
        domain: selectedDomainForAdd,
      }).then((res) => {
        this.modalClose();
        if (res.returncode) {
          if (selectedDomainForAdd == selectedDomainForPage)
            this.getAliasDomainsReqest(selectedDomainForAdd);
          this.makeToastVariant(
            "success",
            res.returndata + ". " + this.$t("COMMON.WAIT_MESSAGE")
          );
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.addformReset();
      });
    },
    /* ------create item end---- */
    /* -------delete item----- */
    saveDeltem(item) {
      this.delItem = item;
    },

    deleteRequest(adomain) {
      postRequest({
        action: "removeAliasDomain",
        token: localStorage.id_token,
        adomain: adomain,
      }).then((res) => {
        this.confirmModalClose("delConfirm-modal");
        if (res.returncode) {
          this.makeToastVariant(
            "success",
            res.returndata + ". " + this.$t("COMMON.WAIT_MESSAGE")
          );
          for (let i = 0; i < this.aliasTable.aliases.length; i++) {
            if (this.aliasTable.aliases[i].alias_domain == adomain) {
              this.aliasTable.aliases.splice(i, 1);
            }
          }
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    deleteOneItem() {
      const item = this.delItem;
      this.deleteRequest(item.alias_domain);
    },
    deleteItems() {
      if (this.aliasTable.selected.length > 0) {
        this.aliasTable.selected.forEach((el) => {
          this.deleteRequest(el.alias_domain);
        });
      }
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },

    /* ----Search---- */
    searchDomain() {
      clearTimeout(this._cusDebounce);
      // delay new call 800ms
      this._cusDebounce = setTimeout(() => {
        if (this.search.length >= 3) {
          postRequest({
            action: "searchAliasDomains",
            token: localStorage.id_token,
            alias_domain: this.search,
          }).then((res) => {
            if (res.returncode) {
              this.aliasTable.aliases = res.returndata;
            } else {
              this.aliasTable.aliases = [];
            }
          });
        } else if (this.search.length == 0) {
          this.getAliasDomainsReqest(this.selectDomain.selectedDomain);
        } 

      }, 800);
    },
     /* ---- change domain dropdown ---- */
     onChangeDomain(newValue) {
      this.getAliasesForSelectedDomain(newValue.domain);
    },
  },
};
</script>
